const SchemePageBayuerdesk = {
    kitPanelItems: [
        {
            id: '0',
            type: 'found',
            title: 'Найдено',
            description: '0',
            color: '#4A92F6'
        }
    ],
    kitColors: [
        '#000000',
        '#0000FF',
        '#FF00FF',
        '#808080',
        '#008000',
        '#00FF00',
        '#ffa500',
        '#000080',
        '#808000',
        '#800080',
        '#FF0000',
        '#C0C0C0',
        '#008080',
        '#FFFF00',
    ],
    kitTableColumns: [
        {
            prop: 'image_src',
            label: 'Фото',
            isSlot: true,
            columnStyles: {
                width: '44px',
                minWidth: '44px',
                padding: '4px 5px',
            }
        },
        {
            prop: 'supplier_id',
            label: 'Оператор',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '150px'
            },
            classes: ['supplier-cell']
        },
        {
            prop: 'gid',
            label: 'GID',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['gid-cell'],
            maxLength: 10,
        },
        {
            prop: 'city_id',
            label: 'Город',
            canSearch: false,
            canSorted: false,
            isSlot: false,
            isModifiered: false,
            columnStyles: {
                width: '120px',
            }
        },
        {
            prop: 'address',
            label: 'Адрес',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isClickEmit: true,
            isModifiered: true,
            columnStyles: {
                width: '300px',
            },
            classes: ['address-cell'],
            maxLength: 80,
        },
        {
            prop: 'code',
            label: 'Ст.',
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                textAlign: 'center',
                width: '70px'
            },
            classes: ['code-cell']
        },
        {
            prop: 'side_turn',
            label: 'Положение',
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '100px'
            }
        },
        {
            prop: 'supplier_tax',
            label: 'Налогообложение',
            columnStyles: {
                width: '120px'
            },
        },
        // {
        //     prop: 'tax',
        //     label: 'НДС',
        //     canSearch: false,
        //     canSorted: false,
        //     isSlot: true,
        //     columnStyles: {
        //         width: '65px'
        //     }
        // },
        {
            prop: 'print_price',
            label: 'Печать без НДС',
            isDate: true,
            isSlot: true,
            isCanCheck: false,
            columnStyles: {
                width: '100px',
                padding: '0',
            },
            classes: ['date-cell']
        },
        {
            prop: 'install_price',
            label: 'Монтаж без НДС',
            isDate: true,
            isSlot: true,
            isCanCheck: false,
            columnStyles: {
                width: '100px',
                padding: '0',
            },
            classes: ['date-cell']
        },
        {
            prop: 'additional_install_price',
            label: 'Доп. монтаж без НДС',
            isDate: true,
            isSlot: true,
            isCanCheck: false,
            columnStyles: {
                width: '100px',
                padding: '0',
            },
            classes: ['date-cell']
        },
        {
            prop: 'type_id',
            label: 'Тип',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '120px',
            }
        },
        {
            prop: 'kind_id',
            label: 'Вид',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '120px'
            }
        },
        {
            prop: 'kind_code',
            label: 'Обозначение',
            canSearch: false,
            canSorted: false,
            columnStyles: {
                width: '100px'
            }
        },
        {
            prop: 'format_id',
            label: 'Фор.',
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '70px',
                textAlign: 'right',
            },
            classes: ['format-cell']
        },
        {
            prop: 'owner_id',
            label: 'Владелец',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '120px'
            }
        },
        {
            prop: 'ots',
            label: 'OTS',
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '70px',
                textAlign: 'right',
            },
            classes: ['ots-cell']
        },
        {
            prop: 'grp',
            label: 'GRP',
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '70px',
                textAlign: 'right',
            },
            classes: ['grp-cell']
        },
        {
            label: 'Направление',
            prop: 'move_direction',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '125px'
            },
            classes: ['direction-cell']
        },
        {
            prop: 'light',
            label: 'Свет',
            canSearch: false,
            canSorted: false,
            isSlot: true,
            columnStyles: {
                width: '65px'
            }
        },
        {
            label: 'Материал',
            prop: 'material_id',
            canSearch: false,
            canSorted: false,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '110px',
                // borderRight: 'none',
            },
            classes: ['material-cell'],
        },
        {
            label: 'Время видео',
            prop: 'video_time',
            canSearch: false,
            canSorted: false,
            columnStyles: {
                width: '100px'
            }
        },
        {
            label: 'Частота видео',
            prop: 'video_frequency',
            canSearch: false,
            canSorted: false,
            columnStyles: {
                width: '100px'
            }
        },
        {
            label: 'Видеоблок',
            prop: 'video_block',
            canSearch: false,
            canSorted: false,
            columnStyles: {
                width: '100px'
            }
        },
        {
            label: 'Выходов в блоке',
            prop: 'video_block_outputs',
            canSearch: false,
            canSorted: false,
            columnStyles: {
                width: '100px'
            }
        },
        {
            label: 'Клиенты видеоблоков',
            prop: 'video_block_clients',
            canSearch: false,
            canSorted: false,
            columnStyles: {
                width: '100px'
            }
        },
        {
            label: 'Режим работы',
            prop: 'operating_mode',
            canSearch: false,
            canSorted: false,
            columnStyles: {
                width: '100px'
            }
        },
        {
            label: 'Комментарий',
            prop: 'comment',
            canSearch: false,
            canSorted: false,
            columnStyles: {
                width: '200px'
            }
        },
        {
            label: 'Статус установки',
            prop: 'mount_status',
            canSearch: false,
            canSorted: false,
            columnStyles: {
                width: '100px'
            }
        },
        {
            label: 'Ссылка',
            prop: 'supplier_link',
            canSearch: false,
            canSorted: false,
            isSlot: true,
            columnStyles: {
                width: '100px'
            }
        },
        {
            label: 'Дата создания',
            prop: 'created_at',
            canSearch: false,
            canSorted: false,
            columnStyles: {
                width: '125px'
            }
        },
        {
            label: 'Дата обновления',
            prop: 'updated_at',
            canSearch: false,
            canSorted: false,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '125px'
            }
        },
        {
            prop: 'more_btn',
            label: '',
            isSlot: true,
            columnStyles: {
                width: '40px',
                minWidth: '40px',
                position: 'sticky',
                right: '0',
                zIndex: '2',
                padding: '0',
                borderLeft: '1px solid #F1F3F9',
            },
        },
    ],
    kitTableBudgetColumns: [
        {
            prop: 'image_src',
            label: 'Фото',
            isSlot: true,
            isShow: true,
            isCustomizable: false,
            columnStyles: {
                width: '44px',
                minWidth: '44px',
                padding: '4px 5px',
            }
        },
        // {
        //     prop: 'image_src1',
        //     label: 'Фото1',
        //     isSlot: true,
        //     isShow: true,
        //     columnStyles: {
        //         width: '44px',
        //         minWidth: '44px',
        //         padding: '4px 5px',
        //     }
        // },
        {
            prop: 'supplier_id',
            label: 'Оператор',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isShow: true,
            isModifiered: true,
            isCustomizable: false,
            columnStyles: {
                width: '150px'
            },
            classes: ['supplier-cell']
        },
        {
            prop: 'address',
            label: 'Адрес',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isShow: true,
            isModifiered: true,
            isCustomizable: false,
            columnStyles: {
                width: '300px',
                cursor: 'pointer'
            },
            classes: ['address-cell'],
            maxLength: 80,
        },
        {
            prop: 'code',
            label: 'Ст.',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isShow: true,
            isModifiered: true,
            isCustomizable: false,
            columnStyles: {
                textAlign: 'center',
                width: '70px'
            },
            classes: ['code-cell']
        },
        // {
        //     prop: 'nds',
        //     label: 'НДС',
        //     isSlot: true,
        //     columnStyles: {
        //         width: '60px',
        //     }
        // },
        {
            prop: 'supplier_tax',
            label: 'Налог',
            isShow: true,
            isCustomizable: false,
            columnStyles: {
                width: '80px'
            },
        },
        // {
        //     prop: 'supplier_tax1',
        //     label: 'Налог1',
        //     isShow: true,
        //     columnStyles: {
        //         width: '80px'
        //     },
        // },
        {
            prop: 'print_price',
            label: 'Печать без НДС',
            isDate: true,
            isSlot: true,
            isShow: true,
            isCustomizable: false,
            columnStyles: {
                width: '100px',
                padding: '0',
            },
            classes: ['date-cell']
        },
        {
            prop: 'install_price',
            label: 'Монтаж без НДС',
            isDate: true,
            isSlot: true,
            isShow: true,
            isCustomizable: false,
            columnStyles: {
                width: '100px',
                padding: '0',
            },
            classes: ['date-cell']
        },
        {
            prop: 'additional_install_price',
            label: 'Доп. монтаж без НДС',
            isDate: true,
            isSlot: true,
            isShow: true,
            isCustomizable: false,
            columnStyles: {
                width: '100px',
                padding: '0',
            },
            classes: ['date-cell']
        },
        {
            prop: 'type_id',
            label: 'Тип',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isShow: true,
            isModifiered: true,
            isCustomizable: true,
            columnStyles: {
                width: '120px',
            }
        },
        {
            prop: 'kind_id',
            label: 'Вид',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isShow: true,
            isModifiered: true,
            isCustomizable: true,
            columnStyles: {
                width: '120px'
            }
        },
        {
            prop: 'format_id',
            label: 'Фор.',
            canSorted: true,
            isSlot: true,
            isShow: true,
            isModifiered: true,
            isCustomizable: true,
            columnStyles: {
                width: '70px',
                textAlign: 'right',
            },
            classes: ['format-cell']
        },
        {
            label: 'Материал',
            prop: 'material_id',
            canSearch: false,
            canSorted: false,
            isSlot: true,
            isShow: true,
            isModifiered: true,
            isCustomizable: true,
            columnStyles: {
                width: '110px',
            },
            classes: ['material-cell'],
        },
        {
            prop: 'owner_id',
            label: 'Владелец',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isShow: true,
            isModifiered: true,
            isCustomizable: true,
            columnStyles: {
                width: '120px'
            }
        },
        {
            prop: 'ots',
            label: 'OTS',
            canSorted: true,
            isSlot: true,
            isShow: true,
            isModifiered: true,
            isCustomizable: true,
            columnStyles: {
                width: '70px',
                textAlign: 'right',
            },
            classes: ['ots-cell']
        },
        {
            prop: 'grp',
            label: 'GRP',
            canSorted: true,
            isSlot: true,
            isShow: true,
            isModifiered: true,
            isCustomizable: true,
            columnStyles: {
                width: '70px',
                textAlign: 'right',
            },
            classes: ['grp-cell']
        },
        {
            prop: 'more_btn',
            label: '',
            isAction: true,
            isSlot: true,
            isShow: true,
            isSlotHeader:true,
            columnStyles: {
                width: '40px',
                minWidth: '40px',
                position: 'sticky',
                right: '0',
                zIndex: '2',
                padding: '0',
            },
            columnsSearchStyle: {
                width: '40px',
                minWidth: '40px',
                position: 'sticky',
                right: '0',
                padding: '0',
            }
        },
    ],
    kitTableRowKeys: [
        'prices',
        'photo',
        'supplier_id',
        'gid',
        'city_id',
        'address',
        'code',
        'type_id',
        'kind_id',
        'owner_id',
        'format_id',
        'ots',
        'grp',
        'material_id',
        'light',
        'move_direction',
        'allPrices',
        'id',
        'marker_id',
        'full_code',
        'booking',
        'nds',
        'tax',
        'catalog_id',
        'more_btn',
        'updated_at',
        'supplier_tax',
        'side_turn',
        'video_time',
        'video_frequency',
        'video_block',
        'video_block_outputs',
        'video_block_clients',
        'comment',
        'mount_status',
        'operating_mode',
        'supplier_link',
        'created_at',
        'kind_code',
        'install_price',
        'additional_install_price',
        'print_price',
        'lat',
        'lng'
    ]
};
export default SchemePageBayuerdesk;
